#banner{background: #1C2347; padding: 20px 20px 70px 20px;}
.banner-header {
    display: grid; 
    grid-template-columns: repeat(2, auto);
    justify-content: space-between; 
    align-items: center; 
    margin-bottom: 50px;
}
.banner-header > div:first-child img {
    width: 180px;
}
.banner-header > div:last-child {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
}
.banner-header p {
    margin: 0 20px 0 0;
}
.banner-header p a {
    color: #FFF;
    font-size: 18px;
    text-decoration: none;
}
.left-content {color: #FFF;}
.banner-central-part {display: grid; grid-template-columns: 1fr 1fr;}
.left-content h4{ font-size: 44px; font-family: 'barlow-700';}
.left-content > h4 > span {color:#FF7D90;display: block;}
.left-content ul{font-size: 18px; font-family: 'Rubik-300'; padding: 0px 0px 0px 20px; margin-top: 30px;margin-bottom: 30px;}
.left-content ul li span{ font-family: 'Rubik-500'; }
.banner-action {margin-bottom: 20px;}
.banner-action span {color: #FFF;}
.banner-central-part >div:last-child img{width: 100%;}
.request-demo {margin-top: -60px; margin-bottom: 50px; background-color: #FFFFFF; padding: 30px 50px 40px 50px; align-items: center; box-shadow: 0px 0px 61px rgba(0, 0, 0, 0.25); border-radius: 5px; }
.request-demo form {align-items: end;display: grid;grid-template-columns: 2fr 2fr 2fr 1fr;}
.request-demo form div {margin-right: 20px;}
.request-demo form div:last-child {margin-right: 0;}
.request-demo form input{width: 100%; margin-bottom: 0px;}
#execute-any-number{background: linear-gradient(269.03deg, #1C2347 1.81%, #0D7DED 258.09%);}
.trusign-power{display: grid; grid-template-columns: repeat(auto-fit,minmax(300px,1fr)); padding: 20px;}
.left-side-power-content {padding: 25px;}
.left-side-power-content h3{font-family: 'barlow-700'; font-size: 32px; color: #FFFFFF; margin-top: 25px;}
.left-side-power-content p{font-family: 'rubik-300'; font-size: 18px; color: #FFFFFF; margin-top: 40px}
.right-side-power-image img{width: 100%;}
.right-side-power-image {padding: 30px;}
.trusted-complete {margin-bottom: 25px; font-family: 'barlow-700'; font-size: 25px;}
#certified-company {text-align: center;padding: 50px 0; font-family: 'barlow-600'; background: #F8F8F9;}
#certified-company > div {display: grid;grid-template-columns: repeat(auto-fit,minmax(200px,1fr)); grid-row-gap: 1rem;}
#certified-company img {width: 120px;}
#why-trusign {padding: 70px 0;}
.company-images {display: grid; grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr ;}
.sign-with-ease {text-align: center; font-family: 'barlow-700';}
.sign-with-ease p {font-size: 18px;margin-bottom: 0;}
.sign-with-ease h4 {font-size: 32px;margin-bottom: 85px;}
.sign-with-ease span{color: #FF7D90;}
.features {display: grid; grid-template-columns: 1fr 1fr 1fr; margin-top: 50px; margin-bottom: 50px;}
.features > div:first-child {margin-right: 20px;}
.features > div:nth-child(2) {margin-left: 10px;margin-right: 10px;}
.features > div:last-child {margin-left: 20px;}
.main-features div{text-align: center;}
.mainfeature-images {margin-top: -50px;}
.mainfeature-images img {width: 80px;}
.main-features {border: 1px solid #E9EFFD;padding: 20px;}
.main-features h5 {font-family: 'barlow-700';margin: 20px 0 15px 0;}
.main-features p {font-family: 'rubik-400';margin-bottom: 10px;color: #777777;}
.why-choose-us {display: grid;grid-template-columns: 1fr 1fr;}
.left-content-image img{width: 100%;}
.right-side-content {padding-top: 50px; padding-left: 30px;}
.right-side-content span{color: #FF7D90; font-family: 'barlow-700';}
.right-side-content h4{font-family: 'barlow-700'; font-size: 32px; margin-bottom: 20px; margin-top: 10px;}
.right-side-content ul{font-family: 'rubik-400'; color:#777777; padding-left: 10px}
.work-process span{color:#FF7D90; font-family: 'barlow-700';}
.work-process h4{color:#1C2347; font-family: 'barlow-700'; font-size: 32px;}
.work-process {text-align: center; margin-bottom: 150px;}
.step-in-process{display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; background-image: url('./streamline-background.png'); background-repeat: no-repeat;}
.upload-document {margin-right: 40px;}
.upload-document span{font-family: 'rubik-400'; color: #777777;}
.upload-document h5{color: #1C2347; font-family: 'barlow-600';}
.upload-document >div:last-child >span:first-child{color: #FF7D90; font-family: 'barlow-900'; font-size: 26px;}
.upload-document >div:first-child { margin-top: -85px;}
.add-signer >div:last-child >span:first-child{color: #FF7D90; font-family: 'barlow-900'; font-size: 26px;}
.add-signer h5{color: #1C2347; font-family: 'barlow-600';}
.add-signer {margin-right: 50px;}
.add-signer span{font-family: 'rubik-400'; color: #777777;}
.add-signer >div:first-child{margin-top: -70px;}
.Document-e-signed >div:last-child >span:first-child{color: #FF7D90; font-family: 'barlow-900'; font-size: 26px;}
.Document-e-signed {margin-right: 50px;}
.Document-e-signed span{font-family: 'rubik-400'; color: #777777;}
.Document-e-signed h5{color: #1C2347; font-family: 'barlow-600';}
.Document-e-signed >div:first-child{margin-top: 5px}
.Shared-with-all-parties >div:last-child >span:first-child{color: #FF7D90; font-family: 'barlow-900'; font-size: 26px}
.Shared-with-all-parties h5{color: #1C2347; font-family: 'barlow-600';}
.Shared-with-all-parties span{font-family: 'rubik-400'; color: #777777;}
.Shared-with-all-parties >div:first-child{margin-top: -25px;}
#affordable-e-solution { margin-top: 50px; background-size: 100% 100%;} 
.pricing {border-radius: 19px; display: grid; grid-template-columns: 1fr 300px; color: white; padding: 20px; align-items: center; background-image: url('./background-image.png'); background-repeat: no-repeat;}
.pricing >div:first-child {padding: 20px;}
.pricing >div:first-child span{color: #FF7D90; font-family: 'barlow-700';}
.pricing >div:last-child img{width: -webkit-fill-available;}
.pricing >div:first-child h4{font-size: 45px; font-family: 'barlow-700';}
.document-pricing {display: grid; grid-template-columns: 190px 1fr; margin-top: 50px; align-items: center}
.document-pricing span { font-size: 18px; font-family: 'barlow-400';}
.document-pricing p{margin-bottom: 0; font-size: 40px; font-family: 'barlow-700'; margin-left: 30px;}
.tru-sign-pricing { text-align: center; margin-top: 50px;}
.tru-sign-pricing span{ font-family: 'barlow-700'; font-size: 18px; color: #FF7D90;}
.tru-sign-pricing h4{ font-family: 'barlow-700'; font-size: 42px;}
.tru-sign-pricing h3{ font-family: 'rubik-400'; font-size: 18px; color: #1C2347;}
#trusign-pricing { background-image: url('./slab-background.png'); background-size: 100% 75%;}
.slabing { background-color: white; box-shadow: 0px 0px 30px 0px #00000026; padding: 50px; }
.slab-1{ margin-right: 5px;display: grid;height: 100%; align-content: space-between;}
.slab-1 h4{ font-family: 'barlow-700'; font-size: 26px; text-align: center; color: #1C2347;}
.slab-1 button:hover{ background-color:#FF7D90; color: #FFFFFF;}
.slab-1 button{ margin-top: 40px;}
.slab-points {font-family: 'rubik-400'; font-size: 16px; color: #6B6B6B;}
.slab-points h4 span{font-family: 'rubik-400'; font-size: 12px;}
.slab-points ul{padding-left: 0;}
.slab-points ul li{margin-bottom: 10px;}
.functionality-esign {display: grid; grid-template-columns: repeat(auto-fit,minmax(300px,1fr)); grid-column-gap:1rem; grid-row-gap:3rem; margin-bottom: 30px; margin-top: 30px;}
.functionality {display: grid; grid-template-columns: 1fr 1fr 1fr; margin-bottom: 30px}
.e-sign-benefits {text-align: center; font-family: 'barlow-700'; margin-top: 50px;}
.e-sign-benefits h4{margin-bottom: 50px;     font-size: 32px; }
.e-sign-benefits span{color:#FF7D90;}
.increased-efficiency {margin-right: 20px; margin-top:2rem}
.increased-efficiency >div > img {width: 60px;}
.increased-efficiency >div:last-child{ padding-left:25px;}
.increased-efficiency >div:last-child span{font-family: 'rubik-400'; color: #777777;}
.increased-efficiency h5{font-size: 20px; font-family: 'barlow-700'; margin-bottom: 0; margin-top: 20px;}
.improved-security {margin-right: 20px;}
.improved-security >div:last-child {padding-left:25px;}
.improved-security >div:last-child span{font-family: 'rubik-400'; color: #777777;}
.improved-security h5{font-size: 20px; font-family: 'barlow-700'; margin-bottom: 0; margin-top: 20px;}
.increased-compliance {margin-right: 20px;}
.increased-compliance >div:last-child {padding-left:25px;}
.increased-compliance >div:last-child span{font-family: 'rubik-400'; color: #777777;}
.increased-compliance h5{font-size: 20px; font-family: 'barlow-700'; margin-bottom: 0; margin-top: 20px;}
.information-box {display: grid; grid-template-columns: 1fr 1fr; padding: 50px 20px 50px 20px; align-items:center;}
#pro-solution {background-color:#1C2347; }
.address-info {color: #FFFFFF;}
.address-info span{font-family: 'barlow-700'; color: #FF7D90;}
.address-info h4{font-size: 35px; font-family: 'barlow-700'; margin-top: 20px; margin-bottom: 60px;}
.personal-info form { padding: 70px; background-size: 100% 100%; background-image: url('./form-background.png');}
.logo-icons > div {display: grid; grid-template-columns: 80px 1fr;margin-top: 20px;color: #FFFFFF;align-items: center;}
.logo-icons > div > div > img {width: 65px;}
.logo-icons p {font-size: 18px; font-family: 'barlow-600'; margin-bottom: 0;}
.logo-icons span {font-size: 14px; display: block;}
#convenient-e-signatures {background-image: url('./electronic-signature.png');background-size: 100% 100%; margin-top: 50px}
.inside-black-box {height: 50vh; padding: 20px; display: grid; place-items: center; align-content: center; color: #FFF; font-family: 'barlow-600'; font-size: 32px; text-align: center;}
.inside-black-box h4{font-size: 32px; margin-bottom: 50px}
.br-40{
    border-radius: 40px;
}

/* ===== ----- Mobile responsive ----- ===== */
@media only screen and (max-width: 600px){
    .banner-central-part {display: grid; grid-template-columns: 1fr;}
    .request-demo form input{width: 100%; margin-bottom: 15px;}
    .banner-action{display: grid; grid-template-columns: 1fr;}
    .request-demo form{display:block;}
    .request-demo form >div:last-child {margin-top: 15px;}
    .features {display: block;}
    .features > div:first-child {margin-bottom: 50px; margin-right: 0;}
    .features > div:nth-child(2) {margin-left: 0px;margin-right: 0px; margin-bottom: 50px;}
    .features > div:last-child {margin-left: 2px;}
    .why-choose-us { margin-top:20px; display: block; margin-bottom: 70px;}
    .right-side-content {padding-top: 0px; padding-left: 0px;}
    .upload-document {margin-right: 0px;}
    .add-signer {margin-right: 0px;}
    .upload-document >div:first-child img{text-align: center; margin-top: 0px;}
    .add-signer >div:first-child img{text-align: center; margin-top: 80px;}
    .Document-e-signed >div:first-child img{text-align: center; margin-top: 40px;}
    .Shared-with-all-parties >div:first-child{margin-top: 40px;}
    .upload-document >div:last-child {text-align: center;}
    .upload-document >div:first-child {text-align: center;}
    .add-signer >div:last-child {text-align: center;}
    .add-signer >div:first-child {text-align: center;}
    .Document-e-signed {margin-right: 0px;}
    .Document-e-signed >div:last-child {text-align: center;}
    .Document-e-signed >div:first-child {text-align: center;}
    .Shared-with-all-parties >div:last-child {text-align: center;}
    .Shared-with-all-parties >div:first-child {text-align: center;}
    .streamline-signature {display: block; margin-top: 80px;}
    .step-in-process{display: block; background-image: none;}
    .pricing {display: block; background-size: 100% 100%;}
    .pricing p{margin-left: 0px;}
    .document-pricing {display: block; margin-top: 20px;}
    .functionality {display: block; margin-bottom: 30px}
    .information-box {display: block; padding: 50px 20px 50px 20px;}
    .left-content-image img{width: 100%; margin-bottom: 25px;} 
    .personal-info form { padding: 25px; background-size: 100% 100%; background-color: white; margin-top: 25px; background-image:none;}
    .work-process {text-align: center; margin-bottom: 110px;}
    .banner-header { grid-template-columns: 1fr;}
    .banner-header > div:last-child {margin-top: 15px;justify-content: space-between;}
}

/*=================css for header buttons by saif (21/06/2023)========================== */
.btn-book-demo{
    width: 161px;
    padding: 0;
    background-color: #FFFFFF;
    border-radius: 47px;
    color: #0886B4;
    font-weight:700;
    margin-right: 26px;
    overflow: hidden;
    border:none;
}

.btn-book-demo-txt{
    background: linear-gradient(180deg, #0886B4 0%, #19BAA8 100%);
    padding: 14px 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border-radius: 47px;
    border: 1px solid #41FFF1;
}

.btn-book-demo-txt:hover{
    background: #02A35F;
    border: 1px solid  #02A35F;
    -webkit-text-fill-color: #FFFFFF;
    color: #FFFFFF;
}

.btn-book-demo:hover{
    box-shadow: 0px 0px 10px rgba(0, 151, 190, 0.24);
}

.primary-button {color: #FFF;background-color: #FF7D90;transition: 1s;}
.primary-button:hover {color: #FFF;box-shadow: inset 14.5rem 0 0 0 #FF556E, inset -14.5rem 0 0 0 #FF556E;}

.btn-login{
    width: 161px;
    color: #FFF;
    background-color: #FF7D90;
    transition: 1s;
    font-size: 16px;
    font-weight: 700;
    padding: 14px 0;
    border-radius: 47px;
    border:none;
    outline:hidden;
}

.btn-login:hover{
    color: #FFF;
    box-shadow: inset 14.5rem 0 0 0 #FF556E, inset -14.5rem 0 0 0 #FF556E;
}

/* ========================================================== */

/*=============== Css for the certified icons by saif (20/06/2023) =================*/
.certified-icon {
    margin-bottom: 30px;
}
.certified-icon-container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
}
.icon-container-iso, .icon-container-gdpr {
    width: 60px;
}
.icon-container-soc2type2 {
    width: 40px;
}
.icon-container-cisa {
    width: 130px;
    margin-top: 1rem;
}
.icon-container-iso, .icon-container-gdpr, .icon-container-soc2type2, .icon-container-cisa {
    margin-right: 25px;
}
.icon-img{
    width: 100%;
    height: 100%;
}
/* ============================================================== */

/*=============== Css for the Request demo modal by saif (20/06/2023) =================*/
.requestdemo-modal-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;
}

.requestdemo-modal-background{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(43, 43, 43, 0.29);
}

.requestdemo-modal-content{
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 1026px;
    max-height: 80vh;
    border-radius: 10px;
    box-shadow: 0px 4px 31px rgba(255, 125, 144, 0.3);
}
/*==========================================================*/

@media only screen and (max-width:600px) {
    #trusign-pricing{
        background-image: none;
    }
}

@media only screen and (max-width:400px) {
    .banner-header > div:last-child{
        display: block;
        margin-top: 2rem;
    }

    .btn-book-demo{
        margin-bottom: 1rem;
    }
}