.requestdemo-container{
    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: row nowrap;
    overflow-x: hidden;
    border-radius: 10px;
    overflow: hidden;
}

.requestdemo-content{
    width: 45%;
    background: linear-gradient(336.52deg, #C5CCEF -1.59%, #FF7D90 -1.58%, #1C2347 99.07%);
    padding-top:65px;
    padding-left:65px
}

.requestdemo-content-text-container{    
    margin-bottom: 95px;
}

.requestdemo-content-text-pink , .requestdemo-content-text-white{
    color:#FF7D90;
    font-size: 2.25rem;
    font-weight: 700;
}

.requestdemo-content-text-white{
    color:#FFFFFF;
}

.requestdemo-content-icon-container-location , .requestdemo-content-icon-container-email{
    display: flex;
    flex-flow: row nowrap;
    color: #ffffff;
}  

.requestdemo-content-icon-container-email{
    margin-top: 50px;
}

.requestdemo-content-icon{
    width: 32px;
    height:32px;
    margin-right: 13px;
}

.icon-text-header{
    margin-top: 2px ;
}

.icon-text-header{
    font-size: 22px;
    font-weight: 700;
    line-height: 20px;
}

.icon-text{
    font-size: 1rem;
    font-weight: 400;
    line-height: 3px;
}

.requestdemo-form-container{
    width: 55%;
    height: 100%;
    padding: 2rem;
    background-color: #ffffff;
}

.requestdemo-form-header{
    display: flex;
    justify-content: space-between;
}

.requestdemo-form-header{
    font-size: 26px;
    font-weight:700;
    padding-bottom: 25px;
    margin-bottom: 35px;
    border-bottom: 1px solid rgba(196, 196, 196, 0.86);
}

.cross-icon-container{
    width: 30px;
    height: 30px;
    transform: translateY(6px);
    cursor: pointer;
}

.cross-icon-img{
    transform: translateY(-8px);
}

.requestdemo-form-content-text{
    font-size: 13px;
    color:  #3F4254;
    font-weight: 500;
}

.requestdemo-form-input{
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 13px;
    color: #929191;
    font-weight: 400;
}

