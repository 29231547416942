#footer {padding: 70px 0;color: #FFF;background-color: #1C2347;}
#footer p {font-size: 18px;}
#footer hr {border-top: 1px solid #000;}
.footer-heading {color: #FFF;font-size: 25px;margin-bottom: 10px;font-family: 'barlow-500';}
.footer-list {list-style-type: none;padding-left: 0;margin-bottom: 0;}
.footer-list li a {color: #FFF;font-family: 'barlow-500';text-decoration: none;}
.footer-list li a span {color: #E14141;font-family: 'barlow-600';font-size: 20px;}
.contact-info h5 {color: #E16565;margin-top: 30px;font-size: 18px;font-family: 'barlow-500';}
.contact-info p {color: #FFF;margin-bottom: 0;border-bottom: 1px solid #E16565;padding: 5px;}
.contact-info p:last-child {border-bottom: 0;}
